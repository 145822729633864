import { motion } from "framer-motion";
import { TbRobot } from "react-icons/tb";

interface AiBotLoaderProps {
  isAnimating?: boolean;
  size?: number | string;
  color?: string;
  className?: string;
}

/**
 * A reusable animated robot icon component
 *
 * @param isAnimating - Whether the icon should be pulsing (defaults to true)
 * @param size - Size of the icon in pixels (defaults to 16px)
 * @param color - CSS color value (defaults to primary-500)
 * @param className - Additional CSS classes
 */
export const AiBotLoader = ({
  isAnimating = true,
  size = "1rem",
  color = "var(--mantine-color-primary-6)",
  className = "",
}: AiBotLoaderProps) => {
  const sizeValue = typeof size === "number" ? `${size}px` : size;

  return (
    <motion.div
      className={className}
      animate={
        isAnimating
          ? {
              scale: [1, 1.1, 1],
              opacity: [0.7, 1, 0.7],
              transition: {
                duration: 1.5,
                repeat: Infinity,
                ease: "easeInOut",
              },
            }
          : {}
      }
    >
      <TbRobot
        style={{
          color,
          width: sizeValue,
          height: sizeValue,
        }}
      />
    </motion.div>
  );
};

export default AiBotLoader;
