import { AnthropicProvider } from "@ai-sdk/anthropic";
import { OpenAIProvider } from "@ai-sdk/openai";

// Only include the required fields in ModelConfig
type ModelConfig = {
  provider: AnthropicProvider | OpenAIProvider;
  model: Parameters<OpenAIProvider>[0] | Parameters<AnthropicProvider>[0];
  label: string;
};

// Only export the static configurations
export const modelConfigs: {
  [K in
    | "anthropic"
    | "anthropic-thinking"
    | "openai"
    | "4o-mini"
    | "gpt-4-turbo"
    | "claude-3-5-sonnet"]: Omit<ModelConfig, "provider">;
} = {
  anthropic: {
    model: "claude-3-7-sonnet-20250219",
    label: "Claude 3.7 Sonnet",
  },
  "anthropic-thinking": {
    model: "claude-3-7-sonnet-20250219",
    label: "Claude 3.7 Sonnet (Thinking)",
  },
  openai: {
    model: "gpt-4o",
    label: "ChatGPT 4o",
  },
  "4o-mini": {
    model: "gpt-4o-mini",
    label: "ChatGPT 4o Mini",
  },
  "gpt-4-turbo": {
    model: "gpt-4-turbo",
    label: "ChatGPT 4 Turbo",
  },
  "claude-3-5-sonnet": {
    model: "claude-3-5-sonnet-latest",
    label: "Claude 3.5 Sonnet",
  },
} as const;

export type ModelProvider = keyof typeof modelConfigs;

// Derive modelOptions from modelConfigs to avoid duplication
export const modelOptions = Object.entries(modelConfigs).map(
  ([key, config]) => ({
    value: key,
    label: config.label,
  }),
);
