import { useChat } from "@ai-sdk/react";
import type { Attachment } from "@ai-sdk/ui-utils";
import { Box, Loader, Text } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { useSearchParams } from "@remix-run/react";
import type { Message } from "ai";
import { useEffect, useRef, useState } from "react";
import { ModelProvider } from "~/ai-configs/models";

import { AiBotLoader } from "./AiBotLoader";
import { EditorContextProvider, useEditorContext } from "./EditorContext";
import { MessageDisplay } from "./MessageDisplay";
import { MessageInput } from "./MessageInput";
import { useMessageLimits } from "./useMessageLimits";

interface AISdkAssistantProps {
  emptyStateMessage?: string;
  editorContext?: Record<string, unknown>;
  initialInput?: string;
  initialAttachments?: Attachment[];
  configId: string;
  systemPromptId?: number | null;
  resetChatRef?: React.MutableRefObject<(() => void) | undefined>;
}

// The implementation component that handles the chat UI
function AISdkAssistantImpl({
  emptyStateMessage = "Directly edit the content, or ask me to help you make some edits!",
  editorContext,
  initialInput,
  initialAttachments = [],
  configId,
  systemPromptId,
  resetChatRef,
  chatId,
  initialMessages,
  initialInputSubmittedRef,
  initialMessagesLoading,
}: AISdkAssistantProps & {
  chatId: string;
  initialMessages: Message[];
  initialInputSubmittedRef: React.MutableRefObject<boolean>;
  initialMessagesLoading: boolean;
}) {
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const [modelProvider, setModelProvider] = useLocalStorage<ModelProvider>({
    key: "ai-sdk-model-provider-v0",
    defaultValue: "anthropic",
  });
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { additionalEditorContext } = useEditorContext();

  const { messages, input, handleSubmit, status, setInput, stop, setMessages } =
    useChat({
      maxSteps: 6,
      api: "/api/chat",
      initialInput,
      initialMessages,
      id: chatId,
      experimental_prepareRequestBody: ({ messages }) => {
        const lastMessage = messages[messages.length - 1];
        return {
          chatId,
          message: lastMessage,
          editorContext: {
            ...editorContext,
            ...additionalEditorContext,
          },
          model: modelProvider,
          configId,
          systemPromptId,
        };
      },
      onError: (error) => {
        console.error("UHOH", error);
      },
    });

  // Only submit initialInput when component is ready
  useEffect(() => {
    if (initialInput && !initialInputSubmittedRef.current) {
      initialInputSubmittedRef.current = true;
      const submitEvent = new Event(
        "submit",
      ) as unknown as React.FormEvent<HTMLFormElement>;

      // Submit with attachments if available
      if (initialAttachments && initialAttachments.length > 0) {
        handleSubmit(submitEvent, {
          experimental_attachments: initialAttachments,
        });
      } else {
        handleSubmit(submitEvent);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = () => {
    if (!messagesContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } =
      messagesContainerRef.current;
    const isAtBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 10;

    if (!isAtBottom) {
      setUserHasScrolled(true);
    } else {
      setUserHasScrolled(false);
    }
  };

  // Define the reset function
  const resetChat = () => {
    // Clear all messages but preserve the input
    setMessages([]);

    // Reset scroll state
    setUserHasScrolled(false);

    // Ensure scroll position is reset
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = 0;
    }
  };

  // Expose the resetChat function via the ref
  useEffect(() => {
    if (resetChatRef) {
      resetChatRef.current = resetChat;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetChatRef]);

  useEffect(() => {
    if (messagesContainerRef.current && !userHasScrolled) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages, status, userHasScrolled]);

  const isLoading =
    initialMessagesLoading ||
    (status !== "streaming" && status !== "ready" && status !== "error");

  // Determine if square should animate - we want animation for loading and streaming states
  const isAnimating = status === "streaming" || isLoading;

  // Use the message limits hook with testing values
  const { isAtLimit, MessageLimitWarning } = useMessageLimits({
    messages,
    resetChatFn: resetChat,
  });

  return (
    <Box className="relative flex h-full flex-col bg-beige-50">
      <Box
        ref={messagesContainerRef}
        className="flex-1 overflow-y-auto px-6 py-4"
        onScroll={handleScroll}
      >
        {messages.length === 0 ? (
          <Box className="flex h-full items-center justify-center text-center">
            <Text size="lg" className="text-gray-400 max-w-md">
              {emptyStateMessage}
            </Text>
          </Box>
        ) : (
          <Box className="space-y-8">
            {messages.map((message, idx) => (
              <Box
                key={`${message.role}-${idx}`}
                className={
                  message.role === "user"
                    ? "rounded-md p-4 border border-gray-200 bg-gray-50 text-gray-900 shadow-sm"
                    : "pl-1"
                }
              >
                <MessageDisplay message={message} configId={configId} />
              </Box>
            ))}
            <Box className="flex items-center gap-2 p-3 text-gray-600 pl-1">
              <AiBotLoader
                size="1.25rem"
                isAnimating={isAnimating}
                className="mr-2"
              />
              {isLoading && <Text size="sm">Thinking...</Text>}
            </Box>
          </Box>
        )}
        {status === "error" && (
          <Box className="absolute bottom-[120px] left-0 right-0 mx-4 mb-4 border border-red-200 rounded bg-red-50 px-3 py-2">
            <Text size="sm" className="text-red-600">
              An error occurred. Please try again.
            </Text>
          </Box>
        )}
      </Box>

      {/* Add message limit warning */}
      <Box className="px-6">
        <MessageLimitWarning />
      </Box>

      <MessageInput
        disabled={
          (status !== "ready" && status !== "error") ||
          !input.trim() ||
          isLoading ||
          isAtLimit
        }
        inputDisabled={isAtLimit}
        input={input}
        modelProvider={modelProvider}
        onChange={setInput}
        onModelChange={setModelProvider}
        onSubmit={handleSubmit}
        status={status}
        onStop={stop}
        configId={configId}
      />
    </Box>
  );
}

// Simplified container component
export default function AISdkAssistant(props: AISdkAssistantProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialMessages, setInitialMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const chatIdRef = useRef<string | null>(null);
  // Move the ref to the container component so it persists
  const initialInputSubmittedRef = useRef(false);

  // Initialize chat ID once on component mount if not in URL
  useEffect(() => {
    const initializeChatId = async () => {
      const currentChatId = searchParams.get("chatId");

      // If we already have a chat ID, use it
      if (currentChatId) {
        chatIdRef.current = currentChatId;
        return;
      }

      try {
        // Create a new chat
        const response = await fetch("/api/chat/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to create chat: ${response.statusText}`);
        }

        const chatData = await response.json();
        const newChatId = chatData.id.toString();

        // Update URL with new chat ID
        searchParams.set("chatId", newChatId);
        setSearchParams(searchParams);
        chatIdRef.current = newChatId;
      } catch (error) {
        console.error("Failed to create chat ID:", error);
      }
    };

    initializeChatId();
  }, [searchParams, setSearchParams]);

  // Load messages whenever the chat ID in the URL changes
  useEffect(() => {
    const fetchMessages = async () => {
      const chatId = searchParams.get("chatId");

      // Don't fetch if we don't have a chat ID yet
      if (!chatId) {
        return;
      }

      setIsLoading(true);

      try {
        // Use a GET request with query parameters instead of POST with body
        const response = await fetch(`/api/chat?chatId=${chatId}`, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch chat messages: ${response.statusText}`,
          );
        }

        const data = await response.json();
        setInitialMessages(data.messages || []);
      } catch (error) {
        console.error("Error loading chat messages:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMessages();
  }, [searchParams]);

  const chatId = searchParams.get("chatId");

  // If we somehow still don't have a chat ID (should be rare), show error
  if (!chatId) {
    return (
      <Box className="flex h-full items-center justify-center text-center">
        <Loader size="xl" />
      </Box>
    );
  }

  return (
    <EditorContextProvider>
      <AISdkAssistantImpl
        {...props}
        initialMessagesLoading={isLoading}
        chatId={chatId}
        initialMessages={initialMessages}
        initialInputSubmittedRef={initialInputSubmittedRef}
      />
    </EditorContextProvider>
  );
}
