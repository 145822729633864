import { useFetcher, useParams } from "@remix-run/react";
import { questionContentSchema } from "modules/questions/question.types";
import { TypeOf } from "zod";
import { action } from "~/routes/api.standards.$standardId.questions";
import { UpdatedQuestionArtifactDisplay } from "../../teacher.assignments.$assignmentId_.edit/AIUpdatedQuestionDisplay";

export function CreatedQuestion({
  input,
}: {
  input: {
    question: TypeOf<typeof questionContentSchema>;
  };
}) {
  const { standardId } = useParams();
  const fetcher = useFetcher<typeof action>();
  const handleSave = () => {
    fetcher.submit(
      { questionContent: input.question },
      {
        method: "post",
        action: `/api/standards/${standardId}/questions`,
        encType: "application/json",
      },
    );
  };
  return (
    <UpdatedQuestionArtifactDisplay
      question={input.question}
      onSave={handleSave}
      loading={fetcher.state === "submitting"}
      successSave={fetcher.state === "idle" && !!fetcher.data?.question}
    />
  );
}
