import { Box, Button, Paper, Tooltip } from "@mantine/core";
import { questionContentSchema } from "modules/questions/question.types";
import { MdAdd, MdRefresh } from "react-icons/md";
import { TypeOf } from "zod";
import { showSuccessToast } from "~/components/Notifications/customNotifications";
import { uuidv4 } from "~/utils/ids/uuid";
import { QuestionContent } from "../teacher.assignments.$assignmentId/QuestionContent";

export const UpdatedQuestionArtifactDisplay = ({
  question,
  onSave,
  loading,
  successSave,
}: {
  question: TypeOf<typeof questionContentSchema>;
  onSave?: (content: string) => void;
  loading?: boolean;
  successSave?: boolean;
}) => {
  const handleSave = () => {
    if (successSave) {
      if (
        window.confirm(
          "This question has already been saved. Are you sure you want to save another copy?",
        )
      ) {
        onSave?.(JSON.stringify(question));
        showSuccessToast("Question saved");
      }
    } else {
      onSave?.(JSON.stringify(question));
      showSuccessToast("Question saved");
    }
  };

  return (
    <Paper
      shadow="sm"
      radius="md"
      p="lg"
      withBorder
      className="relative bg-primary-25 mb-10 border-l-4 border-l-primary-300 mt-2"
    >
      <Box className="mb-4 pb-1 border-b border-primary-100">
        <QuestionContent
          question={{
            id: 1,
            questionContent: question,
            createdAt: new Date(),
            updatedAt: new Date(),
            createdBy: uuidv4(),
            state: "active",
            knowledgeComponentId: null,
            aiGenerated: true,
          }}
        />
      </Box>

      {onSave && (
        <Tooltip
          label={successSave ? "Save another copy" : "Add to Assignment"}
        >
          <Button
            loading={loading}
            onClick={handleSave}
            className={`w-full ${
              successSave
                ? "bg-secondary-600 text-white hover:bg-secondary-700"
                : "bg-secondary-600 hover:bg-secondary-700 text-white"
            } transition-colors shadow-sm font-medium`}
            radius="md"
            size="md"
            leftSection={
              successSave ? <MdRefresh size={20} /> : <MdAdd size={20} />
            }
          >
            {successSave ? "Save Another Copy" : "Add to Assignment"}
          </Button>
        </Tooltip>
      )}
    </Paper>
  );
};
