import { UseChatHelpers } from "@ai-sdk/react";
import type { Attachment } from "@ai-sdk/ui-utils";
import { ActionIcon, Box, Loader, Select } from "@mantine/core";
import { useOutletContext } from "@remix-run/react";
import { KeyboardEvent, useRef, useState } from "react";
import { MdAttachFile, MdClose, MdSend, MdStop } from "react-icons/md";
import { twMerge } from "tailwind-merge";
import { modelOptions, type ModelProvider } from "~/ai-configs/models";
import { PopoverRenderer } from "~/ai-configs/PopoverRenderer";
import { Editor, type EditorHandle } from "~/components/Editor/Editor";
import { OutletContext } from "~/root";

interface MessageInputProps {
  inputDisabled?: boolean;
  disabled: boolean;
  input: string;
  modelProvider: ModelProvider;
  onChange: (value: string) => void;
  onModelChange: (value: ModelProvider) => void;
  onSubmit: UseChatHelpers["handleSubmit"];
  status: "submitted" | "streaming" | "ready" | "error";
  onStop: () => void;
  placeholder?: string;
  editorClassName?: string;
  configId?: string;
}

interface UploadedAttachment {
  url: string;
  contentType: string;
  fileName: string;
  originalFile?: File; // Keep original file for experimental_attachments if needed
}

// Supported file types
const SUPPORTED_IMAGE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/svg+xml",
  "image/webp",
];

const SUPPORTED_DOCUMENT_TYPES = [
  "text/plain",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
  "text/csv",
  "application/json",
  "application/xml",
  "text/markdown",
];

const SUPPORTED_FILE_TYPES = [
  ...SUPPORTED_IMAGE_TYPES,
  ...SUPPORTED_DOCUMENT_TYPES,
];

export function MessageInput({
  inputDisabled,
  disabled,
  input,
  modelProvider,
  onChange,
  onModelChange,
  onSubmit,
  status,
  onStop,
  placeholder = "What would you like to create?",
  editorClassName,
  configId,
}: MessageInputProps) {
  const editorRef = useRef<EditorHandle>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachments, setAttachments] = useState<UploadedAttachment[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { teacherProfile } = useOutletContext<OutletContext>();

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!disabled && !isUploading) {
        handleSubmitWithAttachments(e);
      }
    }
  };

  // We can simplify this now since we're handling images differently
  const handleEditorChange = (content: string) => {
    onChange(content);
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          // Extract just the base64 data (remove the data URL prefix)
          const base64 = reader.result.split(",")[1];
          resolve(base64);
        } else {
          reject(new Error("Failed to convert file to base64"));
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadFile = async (file: File) => {
    try {
      const base64Data = await fileToBase64(file);

      const response = await fetch("/api/uploadFile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileData: base64Data,
          contentType: file.type,
          fileName: file.name,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const data = await response.json();
      return {
        url: data.url,
        contentType: data.contentType,
        fileName: data.fileName,
        originalFile: file, // Keep the original file for AI SDK attachments
      };
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const handlePaste = async (e: React.ClipboardEvent) => {
    if (
      e.clipboardData &&
      e.clipboardData.files &&
      e.clipboardData.files.length > 0
    ) {
      const files = Array.from(e.clipboardData.files);

      if (files.length > 0) {
        e.preventDefault(); // Prevent default paste behavior

        setIsUploading(true);

        try {
          const uploadPromises = files.map((file) => uploadFile(file));
          const uploadedFiles = await Promise.all(uploadPromises);

          setAttachments((prev) => [...prev, ...uploadedFiles]);
        } catch (error) {
          console.error("Error uploading files:", error);
          // Consider showing an error message to the user
        } finally {
          setIsUploading(false);
        }
      }
    }
  };

  // New drag and drop handlers
  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setIsUploading(true);

      try {
        const files = Array.from(e.dataTransfer.files);
        const validFiles = files.filter(
          (file) =>
            SUPPORTED_FILE_TYPES.includes(file.type) ||
            // Handle files without a standard MIME type but with valid extensions
            SUPPORTED_FILE_TYPES.some((type) =>
              file.name.toLowerCase().endsWith(type.split("/")[1]),
            ),
        );

        if (validFiles.length === 0) {
          console.warn("No valid files found in the dropped items");
          return;
        }

        const uploadPromises = validFiles.map((file) => uploadFile(file));
        const uploadedFiles = await Promise.all(uploadPromises);

        setAttachments((prev) => [...prev, ...uploadedFiles]);
      } catch (error) {
        console.error("Error uploading dropped files:", error);
        // Consider showing an error message to the user
      } finally {
        setIsUploading(false);
      }
    }
  };

  const removeAttachment = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmitWithAttachments = (e: React.FormEvent) => {
    // For the AI SDK, we still send the original files as attachments
    // This gives the best compatibility with the SDK
    const originalAttachments: Attachment[] = attachments
      .filter((att) => att.originalFile)
      .map((att) => ({
        name: att.fileName || undefined,
        contentType: att.contentType,
        url: att.url,
      }));

    console.log("originalAttachments", originalAttachments);
    onSubmit(e, {
      experimental_attachments:
        originalAttachments.length > 0 ? originalAttachments : undefined,
    });

    // Clear attachments after submit
    setAttachments([]);
  };

  // New handler for the paper clip button
  const handleAttachClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle file input change from the paper clip button
  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsUploading(true);

      try {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(
          (file) =>
            SUPPORTED_FILE_TYPES.includes(file.type) ||
            // Handle files without a standard MIME type but with valid extensions
            SUPPORTED_FILE_TYPES.some((type) =>
              file.name.toLowerCase().endsWith(type.split("/")[1]),
            ),
        );

        if (validFiles.length === 0) {
          console.warn("No valid files found in the selected items");
          return;
        }

        const uploadPromises = validFiles.map((file) => uploadFile(file));
        const uploadedFiles = await Promise.all(uploadPromises);

        setAttachments((prev) => [...prev, ...uploadedFiles]);
      } catch (error) {
        console.error("Error uploading selected files:", error);
      } finally {
        setIsUploading(false);
        // Reset the file input so the same file can be selected again if needed
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    }
  };

  return (
    <Box className="p-4">
      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileInputChange}
        multiple
        accept={SUPPORTED_FILE_TYPES.join(",")}
        style={{ display: "none" }}
      />

      {/* Attachment previews */}
      {(attachments.length > 0 || isUploading) && (
        <Box className="mb-2 flex flex-wrap gap-2 p-2 border border-gray-200 rounded bg-gray-50">
          {isUploading && (
            <Box className="flex items-center gap-2 p-2">
              <Loader size="xs" />
              <span className="text-sm text-gray-600">Uploading...</span>
            </Box>
          )}

          {attachments.map((attachment, index) => (
            <Box key={index} className="relative border rounded p-1 bg-white">
              {attachment.contentType.startsWith("image/") ? (
                <div className="h-16 w-24 flex items-center justify-center">
                  <img
                    src={attachment.url}
                    alt={attachment.fileName}
                    className="h-16 w-auto max-w-24 object-contain"
                  />
                </div>
              ) : (
                <div className="h-16 flex items-center justify-center px-3">
                  <span className="text-sm">{attachment.fileName}</span>
                </div>
              )}
              <ActionIcon
                onClick={() => removeAttachment(index)}
                size="xs"
                variant="light"
                color="red"
                className="absolute top-1 right-1"
              >
                <MdClose size={14} />
              </ActionIcon>
            </Box>
          ))}
        </Box>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitWithAttachments(e);
        }}
        className="flex items-center gap-2"
      >
        <Box
          className={`flex-1 border rounded bg-white ${isDragging ? "border-blue-400 bg-blue-50" : ""}`}
          onPaste={handlePaste}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div
            className="relative min-h-[100px]"
            onKeyDown={handleKeyDown}
            role="textbox"
            tabIndex={0}
            aria-label="Message input"
          >
            <Editor
              disabled={inputDisabled}
              placeholder={placeholder}
              value={input}
              onChange={handleEditorChange}
              editorRef={editorRef}
              className={twMerge(
                "min-h-[100px] max-h-[300px] overflow-y-auto px-3 py-2 pb-12 border-none",
                editorClassName,
              )}
              disablePasteImages={true}
            />
            <div className="absolute bottom-0 left-0 right-0 bg-white px-2 py-1 flex justify-between items-center">
              {teacherProfile?.isAdmin ? (
                <Select
                  value={modelProvider}
                  onChange={(value) => onModelChange(value as ModelProvider)}
                  data={modelOptions}
                  size="xs"
                  variant="filled"
                  style={{ width: 140 }}
                />
              ) : (
                <PopoverRenderer configId={configId} />
              )}
              <div className="flex items-center gap-1">
                <ActionIcon
                  onClick={handleAttachClick}
                  disabled={isUploading}
                  color="secondary"
                  variant="subtle"
                  size="lg"
                  aria-label="Attach files"
                >
                  <MdAttachFile size={16} />
                </ActionIcon>
                {status === "streaming" ? (
                  <ActionIcon
                    onClick={onStop}
                    variant="light"
                    color="yellow"
                    size="lg"
                    className="px-2"
                    aria-label="Stop generation"
                  >
                    <MdStop size={16} />
                  </ActionIcon>
                ) : (
                  <ActionIcon
                    type="submit"
                    disabled={disabled || isUploading}
                    variant="light"
                    color="secondary"
                    size="lg"
                    className="px-2"
                    style={{ border: "none" }}
                  >
                    <MdSend size={16} />
                  </ActionIcon>
                )}
              </div>
            </div>
          </div>
        </Box>
      </form>
    </Box>
  );
}
