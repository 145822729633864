import { Box, Text } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { Link, useOutletContext, useSearchParams } from "@remix-run/react";
import type { Message } from "ai";
import { FC, useEffect, useRef } from "react";
import { OutletContext } from "~/root";

interface MessageLimitsConfig {
  messages: Message[];
  resetChatFn: () => void;
  maxLimit?: number;
  warningLimit?: number;
  guestMaxLimit?: number;
}

interface MessageLimitsReturn {
  isAtLimit: boolean;
  isApproachingLimit: boolean;
  MessageLimitWarning: FC;
}

export function useMessageLimits({
  messages,
  resetChatFn,
  maxLimit = 30,
  warningLimit = 20,
  guestMaxLimit = 8,
}: MessageLimitsConfig): MessageLimitsReturn {
  const [searchParams] = useSearchParams();
  const signInRouteWithCurrentSearchParams = `/signin?type=homeAssignmentEditor&${searchParams.toString()}`;
  const { user } = useOutletContext<OutletContext>();
  const isAuthenticated = !!user;

  // Track total messages for the current user (or guest)
  const storageKey = isAuthenticated
    ? `ai-sdk-total-messages-${user.id}-v0`
    : `ai-sdk-total-messages-guest-v0`;

  const [totalMessages, setTotalMessages] = useLocalStorage<number>({
    key: storageKey,
    defaultValue: 0,
  });

  const messageCount = messages.length;

  // Apply different limits based on authentication status
  const effectiveMaxLimit = isAuthenticated ? maxLimit : guestMaxLimit;

  const isApproachingLimit =
    isAuthenticated && messageCount >= warningLimit && messageCount < maxLimit;
  const isAtLimit = isAuthenticated
    ? messageCount >= effectiveMaxLimit
    : totalMessages >= guestMaxLimit;

  // Keep track of previously seen message count to detect new messages
  const prevUserMessageCountRef = useRef<number>(0);

  // Update total message count when new messages are sent
  useEffect(() => {
    if (!isAuthenticated) {
      const userMessages = messages.filter((m) => m.role === "user");
      const currentUserMessageCount = userMessages.length;

      // If we have more user messages than before, increment the total
      if (currentUserMessageCount > prevUserMessageCountRef.current) {
        // Increment by the number of new messages
        const newMessageCount =
          currentUserMessageCount - prevUserMessageCountRef.current;
        setTotalMessages((prevTotal) => prevTotal + newMessageCount);
      }

      // Update the reference for next comparison
      prevUserMessageCountRef.current = currentUserMessageCount;
    }
  }, [isAuthenticated, messages, setTotalMessages]);

  const MessageLimitWarning: FC = () => {
    if (!isApproachingLimit && !isAtLimit) return null;

    return (
      <Box
        className="px-4 py-2 flex items-center justify-between bg-purple-50 rounded-md"
        style={{ borderLeft: "4px solid #6B46C1" }}
      >
        <Text size="sm" className="text-purple-900">
          {isAtLimit
            ? isAuthenticated
              ? "You've reached the maximum message limit for this conversation."
              : "You've reached the guest message limit. Sign in to continue."
            : "This conversation is approaching the message limit. Starting a new chat is recommended."}
        </Text>
        {isAuthenticated ? (
          <button
            onClick={resetChatFn}
            className="ml-2 px-3 py-1 text-sm bg-white text-purple-700 border border-purple-300 rounded hover:bg-purple-50"
          >
            Start a new chat
          </button>
        ) : (
          <Link
            to={signInRouteWithCurrentSearchParams}
            className="ml-2 px-3 py-1 text-sm bg-white text-purple-700 border border-purple-300 rounded hover:bg-purple-50"
          >
            Sign in
          </Link>
        )}
      </Box>
    );
  };

  return {
    isAtLimit,
    isApproachingLimit,
    MessageLimitWarning,
  };
}
