import { useFetcher } from "@remix-run/react";
import { useCallback, type ReactNode } from "react";
import { useAssignmentContext } from "~/components/AssignmentEditor/AssignmentContext";
import { action } from "~/routes/api.public.assignmentQuestions";
import { UpdatedQuestionArtifactDisplay } from "~/routes/teacher.assignments.$assignmentId_.edit/AIUpdatedQuestionDisplay";
import type { AIConfig, ConfigToolParameters } from "../index";
export function AssignmentToolRenderer<T extends AIConfig>(props: {
  type: keyof T["tools"];
  input: ConfigToolParameters<T>[keyof T["tools"]];
}): ReactNode {
  const { assignmentId } = useAssignmentContext();
  const fetcher = useFetcher<typeof action>();

  const onSave = useCallback(
    (content: string) => {
      console.log("onSave", content);
      // create assignment using fetcher
      fetcher.submit(
        JSON.stringify({
          assignmentId,
          questionContent: content,
          aiGenerated: true,
        }),
        {
          method: "POST",
          action: "/api/public/assignmentQuestions",
          encType: "application/json",
        },
      );
    },
    [assignmentId, fetcher],
  );

  if (props.type === "createQuestion") {
    return (
      <UpdatedQuestionArtifactDisplay
        question={props.input.question}
        onSave={onSave}
        loading={fetcher.state !== "idle"}
        successSave={!!fetcher.data?.question}
      />
    );
  }

  return null;
}
