import type {
  ReasoningUIPart,
  TextUIPart,
  ToolInvocationUIPart,
  Message as UIMessage,
} from "@ai-sdk/ui-utils";
import { Box, Image, Text } from "@mantine/core";
import { MdFilePresent } from "react-icons/md";
import { ToolRenderer } from "~/ai-configs/ToolRenderer";
import MarkdownDisplay from "~/components/Markdown/MarkdownDisplay";

interface MessageDisplayProps {
  message: UIMessage;
  configId?: string;
}

export function MessageDisplay({ message, configId }: MessageDisplayProps) {
  const isAssistant = message.role === "assistant";
  const hasAttachments =
    message.experimental_attachments &&
    message.experimental_attachments.length > 0;

  return (
    <Box className={isAssistant ? "space-y-6" : ""}>
      {/* Display attachments if present */}
      {hasAttachments && (
        <Box className="mb-4 flex flex-wrap gap-3">
          {message.experimental_attachments!.map((attachment, index) => {
            const isImage =
              attachment.contentType &&
              attachment.contentType.startsWith("image/");

            if (isImage) {
              return (
                <Box
                  key={index}
                  className="border rounded-md overflow-hidden max-w-xs"
                >
                  <Image
                    src={attachment.url}
                    alt={attachment.name || "Attached image"}
                    className="max-h-64 w-auto object-contain"
                  />
                </Box>
              );
            } else {
              // File placeholder for non-image attachments
              return (
                <Box
                  key={index}
                  className="border rounded-md p-3 flex items-center gap-2 cursor-pointer hover:bg-gray-50"
                  onClick={() => window.open(attachment.url, "_blank")}
                >
                  <MdFilePresent size={24} className="text-gray-600" />
                  <Text size="sm">{attachment.name || "File attachment"}</Text>
                </Box>
              );
            }
          })}
        </Box>
      )}

      {/* Rest of message content display */}
      {message.parts ? (
        <>
          {message.parts.map((part, idx) => {
            if (part.type === "text") {
              return (
                <Box key={idx} className={isAssistant ? "mb-4" : "mb-2"}>
                  <MarkdownDisplay
                    content={(part as TextUIPart).text}
                    disableTextToSpeech
                  />
                </Box>
              );
            }

            if (part.type === "reasoning") {
              const reasoningPart = part as ReasoningUIPart;
              return (
                <Box
                  key={idx}
                  className="my-4 p-4 bg-gray-100 rounded-md border border-gray-300"
                >
                  <Box className="text-sm font-semibold mb-2 text-gray-700">
                    Model Reasoning:
                  </Box>
                  <Box className="whitespace-pre-wrap font-mono text-xs">
                    {reasoningPart.details.map((detail, detailIdx) => (
                      <Box key={detailIdx}>
                        {detail.type === "text" ? (
                          detail.text
                        ) : (
                          <Box className="text-gray-500 italic">
                            &lt;redacted&gt;
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              );
            }

            if (part.type === "tool-invocation") {
              const { toolInvocation } = part as ToolInvocationUIPart;

              if (toolInvocation.state === "result") {
                return (
                  <Box key={idx} className="my-6">
                    <ToolRenderer
                      type={toolInvocation.toolName}
                      input={toolInvocation.result}
                      configId={configId}
                    />
                  </Box>
                );
              }

              // For partial-call or call state
              return (
                <Box
                  key={idx}
                  className="my-3 p-3 bg-gray-50 border border-gray-200 rounded-md font-mono text-xs"
                >
                  {JSON.stringify(toolInvocation.args, null, 2)}
                </Box>
              );
            }

            return null;
          })}

          {/* Add subtle section dividers between assistant message parts */}
          {isAssistant &&
            message.parts.length > 1 &&
            message.parts.some((p) => p.type === "tool-invocation") && (
              <Box className="w-full h-px bg-gray-200 my-6" />
            )}
        </>
      ) : (
        <Box>{message.content}</Box>
      )}
    </Box>
  );
}
