import { createContext, ReactNode, useContext, useState } from "react";

// Create a simple context for additional editor context
interface EditorContextType {
  additionalEditorContext: Record<string, unknown>;
  setAdditionalEditorContext: React.Dispatch<
    React.SetStateAction<Record<string, unknown>>
  >;
}

export const EditorContext = createContext<EditorContextType>({
  additionalEditorContext: {},
  setAdditionalEditorContext: () => {},
});

// Provider component for the EditorContext
export function EditorContextProvider({ children }: { children: ReactNode }) {
  const [additionalEditorContext, setAdditionalEditorContext] = useState<
    Record<string, unknown>
  >({});

  return (
    <EditorContext.Provider
      value={{ additionalEditorContext, setAdditionalEditorContext }}
    >
      {children}
    </EditorContext.Provider>
  );
}

// Custom hook to use the EditorContext
export function useEditorContext() {
  return useContext(EditorContext);
}
