import { questionContentSchema } from "modules/questions/question.types";
import type { ReactNode } from "react";
import type { TypeOf } from "zod";
import { CreatedQuestion } from "~/routes/admin.course-editor/tool-renderers/CreatedQuestion";
import type { AIConfig, ConfigToolParameters } from "./index";
// Import the assignment renderer directly
import { AssignmentToolRenderer } from "./assignmentAssistant/AssignmentToolRenderer";

interface ToolRendererProps<T extends AIConfig> {
  type: keyof T["tools"];
  input: ConfigToolParameters<T>[keyof T["tools"]];
  configId?: string;
}

export function ToolRenderer<T extends AIConfig>({
  type,
  input,
  configId,
}: ToolRendererProps<T>): ReactNode {
  // Use explicit config ID check instead of dynamic lookup
  if (configId === "assignmentAssistant") {
    return <AssignmentToolRenderer type={type} input={input} />;
  }

  // Default renderer
  switch (type) {
    case "createQuestion":
      return (
        <CreatedQuestion
          input={input as { question: TypeOf<typeof questionContentSchema> }}
        />
      );
    default:
      return null;
  }
}
