import { createContext, ReactNode, useContext } from "react";

type AssignmentContextType = {
  assignmentId: string;
};

const AssignmentContext = createContext<AssignmentContextType | undefined>(
  undefined,
);

export function useAssignmentContext(): AssignmentContextType {
  const context = useContext(AssignmentContext);
  if (!context) {
    throw new Error(
      "useAssignmentContext must be used within an AssignmentContextProvider",
    );
  }
  return context;
}

type AssignmentContextProviderProps = {
  assignmentId: string;
  children: ReactNode;
};

export function AssignmentContextProvider({
  assignmentId,
  children,
}: AssignmentContextProviderProps) {
  return (
    <AssignmentContext.Provider value={{ assignmentId }}>
      {children}
    </AssignmentContext.Provider>
  );
}
