import { Button, Group, Popover, TextInput } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { ReactNode, useEffect, useState } from "react";
import { useEditorContext } from "~/components/AISdkAssistant/EditorContext";

interface PopoverRendererProps {
  configId?: string;
}

export function PopoverRenderer({ configId }: PopoverRendererProps): ReactNode {
  const { additionalEditorContext, setAdditionalEditorContext } =
    useEditorContext();
  const [opened, setOpened] = useState(false);

  // Use localStorage to persist course info across sessions
  const [savedCourseInfo, setSavedCourseInfo] = useLocalStorage<{
    courseName: string;
    gradeLevel: string;
  }>({
    key: "assignment-assistant-course-info",
    defaultValue: {
      courseName: "",
      gradeLevel: "",
    },
  });

  // Store temporary values while editing
  const [tempValues, setTempValues] = useState<Record<string, string>>({
    courseName: savedCourseInfo.courseName || "",
    gradeLevel: savedCourseInfo.gradeLevel || "",
  });

  // Initialize the editor context with saved values on component mount
  useEffect(() => {
    if (savedCourseInfo.courseName || savedCourseInfo.gradeLevel) {
      setAdditionalEditorContext((prev) => ({
        ...prev,
        courseName: savedCourseInfo.courseName,
        gradeLevel: savedCourseInfo.gradeLevel,
      }));
    }
  }, [savedCourseInfo, setAdditionalEditorContext]);

  // Initialize temp values when popover opens
  const handleOpen = (isOpen: boolean) => {
    if (isOpen) {
      // Initialize temp values from context when opening
      setTempValues({
        courseName:
          (additionalEditorContext.courseName as string) ||
          savedCourseInfo.courseName ||
          "",
        gradeLevel:
          (additionalEditorContext.gradeLevel as string) ||
          savedCourseInfo.gradeLevel ||
          "",
      });
    }
    setOpened(isOpen);
  };

  // Handler to update temp values
  const handleInputChange = (key: string, value: string) => {
    setTempValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // Save changes to context and localStorage
  const handleSave = () => {
    // Update context
    setAdditionalEditorContext((prev) => ({
      ...prev,
      ...tempValues,
    }));

    // Update localStorage
    setSavedCourseInfo({
      courseName: tempValues.courseName || "",
      gradeLevel: tempValues.gradeLevel || "",
    });

    setOpened(false);
  };

  // Close without saving
  const handleClose = () => {
    setOpened(false);
  };

  // Function to generate button text
  const getButtonText = () => {
    const gradeLevel =
      (additionalEditorContext.gradeLevel as string) ||
      savedCourseInfo.gradeLevel;
    const courseName =
      (additionalEditorContext.courseName as string) ||
      savedCourseInfo.courseName;

    if (gradeLevel || courseName) {
      const truncatedGrade = gradeLevel ? truncateText(gradeLevel, 12) : "";
      const truncatedCourse = courseName ? truncateText(courseName, 8) : "";

      if (gradeLevel && courseName) {
        return `${truncatedGrade}, ${truncatedCourse}`;
      } else {
        return truncatedGrade || truncatedCourse;
      }
    }

    return "Specify Grade & Course";
  };

  // Helper function to truncate text
  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  // Get AI config if available
  if (configId === "assignmentAssistant") {
    return (
      <Popover
        width={300}
        trapFocus
        position="bottom"
        withArrow
        shadow="md"
        opened={opened}
        onChange={handleOpen}
      >
        <Popover.Target>
          <Button
            size="xs"
            color="secondary"
            variant="light"
            onClick={() => handleOpen(!opened)}
          >
            {getButtonText()}
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <TextInput
            label="Grade Level"
            placeholder="eg. 7th, 10th"
            size="xs"
            mt="xs"
            value={tempValues.gradeLevel || ""}
            onChange={(e) =>
              handleInputChange("gradeLevel", e.currentTarget.value)
            }
          />
          <TextInput
            label="Course Name"
            placeholder="eg. NY Regents Living Environment"
            size="xs"
            value={tempValues.courseName || ""}
            onChange={(e) =>
              handleInputChange("courseName", e.currentTarget.value)
            }
          />
          <Group mt="md" justify="flex-end" gap="xs">
            <Button variant="outline" size="xs" onClick={handleClose}>
              Cancel
            </Button>
            <Button size="xs" onClick={handleSave}>
              Save
            </Button>
          </Group>
        </Popover.Dropdown>
      </Popover>
    );
  }
  return <div id="placeholder" />;
}
